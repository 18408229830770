import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'

import styles from 'styles/map'
import { cover, square, boxShadow } from 'styles/helpers'
import { primaryColor, white } from 'styles/colors'
import { media, breakpoints } from 'styles/media'
import useWindowResize from 'hooks/useWindowResize'

import Logo from 'assets/images/logo.svg'

const Container = styled.div`
  ${cover('absolute')};
`

const Marker = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  ${square('72px')};
  background-color: ${primaryColor};
  fill: ${white};
  border-radius: 50%;
  user-select: none;
  transform: translate3d(-50%, -50%, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  ${boxShadow};

  ${media.medium`
    ${square('90px')};
  `};
`

const StyledLogo = styled(Logo)`
  width: 48px;
  height: 22px;
  margin-top: -1px; /* Optically align logo in circle */

  ${media.medium`
    width: 60px;
    height: 27px;
    margin-top: -2px;
  `};
`

const Map = ({ defaultCenter, center, zoom, styled }) => {
  const [currentCenter, setCurrentCenter] = useState(center)
  const size = useWindowResize()

  useEffect(() => {
    if (!defaultCenter) {
      if (size.width < breakpoints.medium) {
        setCurrentCenter({ lat: 45.48893, lng: -73.55838 })
      } else {
        setCurrentCenter({ lat: 45.491498, lng: -73.564751 })
      }
    }
  }, [size.width, defaultCenter])

  return (
    <Container>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBxeJyDNM5d4Cn9VTGNnLG_DBLIR6LrIXM' }}
        defaultCenter={currentCenter}
        center={currentCenter}
        defaultZoom={zoom}
        disableDefaultUI
        options={{
          styles: [
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            ...(styled ? styles : []),
          ],
          disableDefaultUI: true,
        }}
      >
        <Marker lat={45.491498} lng={-73.558447}>
          <StyledLogo />
        </Marker>
      </GoogleMapReact>
    </Container>
  )
}

export default Map
